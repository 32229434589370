import React, { useState } from "react";
import "tailwindcss/tailwind.css";

function App() {
  const [formData, setFormData] = useState({
    sourceAirportCode: "",
    destinationAirportCode: "",
    date: "",
    classOfService: "",
  });

  const [flightData, setFlightData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url = `https://tripadvisor16.p.rapidapi.com/api/v1/flights/searchFlights?sourceAirportCode=${formData.sourceAirportCode}&destinationAirportCode=${formData.destinationAirportCode}&date=${formData.date}&itineraryType=ONE_WAY&numAdults=1&numSeniors=0&classOfService=${formData.classOfService}&pageNumber=1&currencyCode=USD`;

      const options = {
        method: "GET",
        headers: {
          "X-RapidAPI-Key":
            "37f6c5fa6dmsh4e56295a488cf06p180f92jsnb90e6d22d356",
          "X-RapidAPI-Host": "tripadvisor16.p.rapidapi.com",
        },
      };

      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setFlightData(data);
    } catch (error) {
      console.error("Error fetching flight data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
      <form
        className="w-full max-w-md bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit}
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="sourceAirportCode"
          >
            From (Source Airport Code) <br />
            NOTE : Kindly type BOM for Mumbai
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="sourceAirportCode"
            type="text"
            name="sourceAirportCode"
            value={formData.sourceAirportCode}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="destinationAirportCode"
          >
            To (Destination Airport Code)
            <br />
            NOTE : Kindly type DEL for DELHI
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destinationAirportCode"
            type="text"
            name="destinationAirportCode"
            value={formData.destinationAirportCode}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="date"
          >
            Date
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="date"
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="classOfService"
          >
            Class of Service
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="classOfService"
            name="classOfService"
            value={formData.classOfService}
            onChange={handleChange}
            required
          >
            <option value="">Select Class</option>
            <option value="ECONOMY">Economy</option>
            <option value="PREMIUM_ECONOMY">Premium Economy</option>
            <option value="BUSINESS">Business</option>
            <option value="FIRST">First Class</option>
          </select>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Search Flights
          </button>
        </div>
      </form>

      {/* {flightData && <p>Status : {flightData.status.toString()}</p>} */}

      {loading ? (
        <p>Loading...</p> // Show loading state
      ) : flightData && flightData ? (
        <>
          {flightData && !flightData.status ? (
            <>
              <p>{flightData.message[0].date}</p>
            </>
          ) : (
            <>
              <p>
                Flight Company :
                {flightData.data.flights[0].segments[0].legs[0].operatingCarrier.displayName.toString()}
                <br />
                NOTE : It will only show first result only for now, Due to api
                loading time issue
              </p>
            </>
          )}
        </>
      ) : (
        <p>
          {formData.originLocationCode
            ? "No flights found"
            : "Please search for flights"}
        </p>
      )}
    </div>
  );
}

export default App;
